@import "mixin.css";


.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

.size100 {
	@mixin size100;
}

.text-upper {
	text-transform: uppercase;
}

.coupon-field {
	.form-control {
		margin-right: 10px;
		border-radius: 5px !important;
	}
}

.container-fluid {
	max-width: 1920px;
}

.list-no {
	list-style: decimal;
	margin-left: 20px;

	li {
		margin-bottom: 15px;
	}
}


.form-control {
	border-radius: 0 !important;
	border: 1px solid var(--color-primary) !important;
	font-weight: bold;
}


body {
	background-image: url(RESOURCE/img/tiles-hintergrund.png);
	text-shadow: 0 0 5px rgb(0 0 0/15%);
	font-weight: bold;
}

.form-control {
	border-radius: 0 !important;
	background-color: var(--nav-bg-color);
	border: 1px solid var(--color-primary);
	box-shadow: 0 0 5px rgb(0 0 0/15%);
}

h1,
h2,
h3,
h4,
a,
.text-shadow {
	text-shadow: 0 0 5px rgb(0 0 0/15%);
}

.checkbox {
	label {
		&:before {
			box-shadow: 0 0 5px rgb(0 0 0/15%);
		}
	}
}

a {
	pointer-events: unset !important;
}

.container-sm {
	@media (min-width:1200px) {
		max-width: 960px;
	}
}

.frame-bl {
	background-image: url(RESOURCE/img/tiles-hintergrund-hell.png);
	border: 1px solid var(--color-primary);
	padding: 30px;
	box-shadow: 0 0 5px rgb(0 0 0/15%);

	.iframe-heading {
		font-family: var(--font-family-main);
		font-weight: bold;
		color: var(--color-primary);
		text-transform: uppercase;
		letter-spacing: 0;
	}

}

html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);
}



html {
	height: 100%;
}

body {
	@media (min-width: 1200px) {
		position: relative;
		padding-bottom: 215px;
		min-height: 100vh;

		.footer-v1 {
			position: absolute;
			bottom: 6px;
			left: 0;
			right: 0;
			max-width: 1920px;

			@media (min-width:1921px) {
				left: 50%;
				transform: translate(-50%, 0);
				width: 100%;
			}
		}
	}
}

.vo-unit-html {
	position: relative;
	height: auto;

	body {
		position: unset;
	}
}

.bg-texture {
	background-image: url(RESOURCE/img/tiles-hintergrund-hell.png);
}

.bg-texture2 {
	background-image: url(RESOURCE/img/tiles-hintergrund.png);
}

h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);

}

.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: var(--h5-font-color);
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
}

.heading1 {
	text-transform: uppercase;
	font-size: 1.5rem;
	margin: 0;
	text-align: center;
	font-family: var(--font-dengular-display);

	color: var(--color-primary);
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);

	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
}

/*--- background-colors---*/

.bg-primary {
	background-color: var(--color-primary);
}

.bg-primary-light {
	background-color: var(--color-primary-light);
}

.bg-secondary {
	background-color: var(--color-secondary);
}

.bg-secondary-light {
	background-color: var(--color-secondary-light);
}

.bg-white {
	background-color: var(--color-white);
}

.bg-grey-light {
	background-color: var(--color-grey-light);
}

.bg-grey-normal {
	background-color: var(--color-grey-normal);
}

.bg-grey-dark {
	background-color: var(--color-grey-dark);
}

.bg-black {
	background-color: var(--color-black);
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey-dark) !important;
}



.app-loaded #preloader {
	display: none;
}

.section-bl {
	position: relative;
}

.contact-form .checkbox-success input[type=checkbox]:checked+label:after {
	left: -2px;
	top: 1px;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: var(--nav-bg-color);
	opacity: 1;
}

.fav-element {
	cursor: pointer;
	margin-top: 0;



	.fa {
		border: 1px solid var(--color-primary);
		padding: 1px 9px;
		border-radius: 5px;
		font-size: 14px;
		font-weight: bold;
		height: 25px;
		align-items: center;
		display: inline-flex;
		align-items: center;

		font-family: var(--font-family-main);

		&.fa-heart {
			&:before {
				content: "GERMERKT";
			}
		}

		&.fa-heart-o {
			&:before {
				content: "MERKEN";
			}
		}

	}
}

.page-title {
	position: absolute;
	bottom: -20px;
	width: 100%;
	left: 0;
	z-index: 2;
	margin: 0;
	border-bottom: none;
	text-align: right;

	.container {
		position: relative;
	}

	@media (max-width:992px) {
		bottom: 30px;
	}

	.pag-title {
		box-shadow: 0 0 5px rgb(0 0 0/15%);
		display: inline-block;
		font-size: 28px;
		margin-bottom: 0;
		color: var(--color-primary);
		text-transform: uppercase;
		background-color: var(--color-white);
		border: 2px solid var(--color-primary);
		padding: 10px 15px 12px;
		position: absolute;
		bottom: -8px;
		right: 0;
		font-weight: normal;
		line-height: 1.2;
		font-family: var(--font-dengular-display);
		background-image: url(RESOURCE/img/tiles-hintergrund-hell.png);

		@media (max-width:992px) {
			font-size: 24px;
		}

		span {
			text-shadow: 0 0 5px rgb(0 0 0/15%);
		}
	}
}

/*--- Datepicker ---*/

.datepicker-trigger {

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled {
		background-color: var(--cal-available) !important;
		color: var(--cal-text-color) !important;

		&.asd__day--disabled {
			background-color: var(--cal-disabled) !important;
			opacity: 0.8 !important;
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--cal-selected) !important;
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}

			/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: var(--cal-selected) !important;
			opacity: 1 !important;
			color: var(--cal-text-color-hover) !important;
		}
	}

	.asd__day--selected {
		background: var(--cal-selected) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				left: 0px;
			}
		}

	}
}

@media (min-width:1400px) {

	.container,
	.container-xl {
		max-width: 1370px;
	}
}

/*--- Buttons ---*/

.btn {
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	padding: .375rem 1.5rem;
	text-transform: var(--btn-text-transform);
	border: 1px solid var(--color-primary);
	box-shadow: 0 0 5px rgb(0 0 0/15%);
	font-weight: bold;

	&.btn-primary,
	&.btn-go {
		background-color: var(--btn-primary-bg-color);
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-secondary {
		background-color: var(--btn-secondary-bg-color);
		color: var(--btn-secondary-font-color);
		border-color: var(--btn-secondary-border-color);

		&:hover {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover) !important;
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.fa {
			opacity: .4;
			margin-left: 15px;
		}
	}
}

.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;

}

.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 38%;
		left: 0;
		width: 100%;


		@media (max-width:992px) and (min-width:767px) {
			top: 50%;
			transform: translate(0, -38px);
		}

		@media (max-width:767px) {
			top: 50%;
			transform: translate(0, 49px);
		}

		@media (max-width:520px) {
			top: 50%;
			transform: translate(0, 40px);
		}

		@media (max-width:460px) {
			top: 50%;
			transform: translate(0, 20px);
		}

		@media (max-width:400px) {
			top: 50%;
			transform: translate(0, 5px);
		}

		@media (max-width:380px) {
			top: 50%;
			transform: translate(0, -10px);
		}


		button {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 5px rgba(0, 0, 0, .21);
			background-color: var(--color-white) !important;
			position: absolute;
			left: -45px;

			@media (max-width:767px) {
				left: 10px;
			}

			span {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--color-black);
					font-family: "Font Awesome 5 Free";
					position: relative;
					left: 2px;
					font-size: 16px;
					top: 1px;
				}
			}

			&.owl-next {
				position: absolute;
				right: -45px;
				left: initial;

				@media (max-width:767px) {
					right: 10px;
				}

				span {

					&:before {
						content: "\f054";
						left: 3px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}

	.owl-dots {
		@media (max-width:767px) {
			display: none;
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transition: all 0.25s ease 0s;
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.inner-banner {
	background-image: url(RESOURCE/img/demo-banner1.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 400px;
	position: relative;
	max-width: 1920px;
	margin: 0 auto;

	&.blog {
		background-image: url(RESOURCE/img/aktuelles_neu.jpg);
	}

	@media (max-width:992px) {
		height: 330px;
	}


	.page-title {
		position: absolute;
		bottom: -20px;
		width: 100%;
		left: 0;
		z-index: 2;
		margin: 0;
		border-bottom: none;
		text-align: right;

		.container {
			position: relative;
		}

		@media (max-width:992px) {
			bottom: 30px;
		}





	}
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		justify-content: center;
		width: 100%;
		margin: 30px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}

/*@media (min-width:1200px) {
	.container {
		max-width: 1230px;
	}
}*/

@media (min-width:1500px) {
	.container {
		max-width: 1370px;
	}

	.container-xl {
		max-width: 1370px;
	}
}

.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.fal {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}

.covid-section {

	margin: 0;
	position: absolute;
	top: 120px;
	right: 40px;
	z-index: 2;
	transform: rotate(10deg);


	@media (max-width: 1140px) {
		right: 20px;
		top: 120px;
		bottom: inherit;
	}

	@media (max-width: 768px) {
		right: 20px;
		top: 150px;
		bottom: inherit;
	}

	@media (max-width: 560px) {
		right: 10px;
		top: 150px;
		bottom: inherit;
	}


	.btn {
		width: 160px;
		height: 160px;
		border-radius: 100px;
		font-size: 24px;

		@media (max-width:1170px) {
			width: 130px;
			height: 130px;
			white-space: unset;
		}

		@media (max-width: 992px) {
			width: 110px;
			height: 110px;
			font-size: 18px;

		}

		@media (max-width: 768px) {
			width: 98px;
			height: 98px;
			font-size: 13px;

		}
	}


}

.pswp__bg {
	background: var(--color-grey-light) !important;
}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.openstreetwarning {
	position: relative;
	background-color: #000;
	aspect-ratio: 16/9;
	padding: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;


	@media(max-width:500px) {
		padding: 5px;
		font-size: 13px;
	}


	.text {
		text-align: center;
	}


	.button-line {
		text-align: center;
		margin: 10px 0px;


		@media(max-width:500px) {
			margin: 0px 0px;
		}
	}


	a {
		color: var(--color-white);
	}
}

p {
	font-weight: 400;
}

.contact-phone-bl {
	position: relative;
}

.con-bl {
	height: 5px;
	position: absolute;
	top: -100px;


	@media(max-width:767px) {
		top: 0;
	}
}