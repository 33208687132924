.faq-v2 {
	margin-top: var(--page-margin-top);

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	.title {
		font-size: 1.4rem;
		font-size: var(--h3-font-size);
		padding: 10px 3px;
		font-weight: 600;
		margin-bottom: 10px;
		margin-top: 10px;
		text-indent: 5px;
		background-image: url(RESOURCE/img/tiles-hintergrund-hell.png);
		border: 1px solid #05164d;
		border: 1px solid var(--color-primary);
		box-shadow: 0 0 5px rgb(0 0 0 / 15%);
		position: relative;
		padding-left: 30px;

		i {
			position: absolute;
			left: 3px;
			top: 7px;

			img {
				width: 20px;
			}
		}
	}

	.accordion-button {
		font-size: 20px;
	}

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			/*	background-color: var(--faq-title-bg-color);*/
			border-bottom: none;
			padding: 0;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

	.question {
		font-family: 'DINRoundPro';
		font-weight: bold;
	}
}