.hero-image-v1 {
	position: relative;

	@media (max-width:1920px) {
		height: 88vh;
	}

	@media (max-height: 600px) {
		min-height: 680px;

	}

	.picture {
		border: 1px solid var(--color-primary);
		box-shadow: 0 0 8px rgb(0 0 0 / 15%);

		@media (max-width:2000px) {
			height: 88vh;
		}

		/*height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);*/
		margin-top: 71px;

		@media (max-width:992px) {
			margin-top: 60px;

		}

		@media (max-height: 600px) {
			min-height: 680px;

		}

		.img {
			object-fit: cover;
			width: 100%;

			@media (max-width:1920px) {
				height: 100%;
			}
		}
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 20px;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		/*	@media (min-width: 1200px) {
			display: none !important;

		}*/




		.hero-text {
			width: 100%;

			.hero-headline {
				width: 100%;
				text-align: center;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 3vw;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .3);
				font-family: var(--font-family-main);
				font-weight: 400;
				margin-bottom: 0px;
				color: var(--color-primary);
				text-transform: uppercase;

				@media (min-width: 1600px) {
					font-size: 55px;
				}

				@media (max-width: 1140px) {
					font-size: 48px;
				}

				@media (max-width: 992px) {
					font-size: 2.8rem;
					line-height: 3rem;
				}

				@media (max-width: 576px) {
					font-size: 1.65rem;
					line-height: 2.25rem;
				}

				@media (max-height: 600px) {
					font-size: 2rem;
					line-height: 2.5rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.3rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);

				@media (min-width: 1600px) {
					font-size: 2.3rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 1.7rem;
				}

				@media (max-width: 575px) {
					font-size: 1.2rem;
				}
			}
		}

	}
}