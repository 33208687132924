.unit-section-bl {
	.phone-bl {
		@media (max-width:992px) {
			margin-bottom: 60px !important;
		}
	}
}

.button-style {
	background-color: var(--btn-primary-bg-color);
	color: var(--btn-primary-font-color);
	border-color: var(--btn-primary-border-color);
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	padding: .375rem 1.5rem;
	text-transform: var(--btn-text-transform);
	border: 1px solid var(--color-primary);
	box-shadow: 0 0 5px rgb(0 0 0 / 15%);
	font-weight: 700;
	margin-bottom: 20px;
}

.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);
	padding-top: 70px;

	.widget-header {
		display: none;
	}

	.contact-phone-bl {
		@media (min-width:993px) {
			margin-top: 30px;
			margin-bottom: 60px;
		}

	}

	.widget-block {
		border: none !important;
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}
	}

	.no-gap {
		p {
			margin-bottom: 0;
		}
	}

	.customs-bl {
		&.unit-section {
			padding: 50px 50px 20px !important;

			@media (max-width:992px) {
				padding: 50px !important;
			}

			@media (max-width:767px) {
				padding: 30px !important;
			}
		}

		h3 {
			font-family: var(--font-family-main);
			font-weight: bold;
			color: var(--color-primary);
		}
	}

	#direct-booking {
		padding: 50px 50px 20px !important;

		@media (max-width:767px) {
			padding: 30px !important;
		}
	}

	.unit-section {
		h2 {
			text-transform: uppercase;
			font-size: 1.5rem;
			margin: 0;
			text-align: center;
			font-family: var(--font-family-main);
			font-weight: bold;
			color: var(--color-primary);
		}

		&.calendar-bl {
			padding: 50px 50px 20px !important;

			@media (max-width:992px) {
				padding: 50px 25px 20px !important;
			}

			@media (max-width:767px) {
				padding: 30px 0px !important;
			}
		}
	}

	.left-col {
		padding: 0px 15px;

		.dlg {
			&.show {
				.box {
					.header {
						background-image: url(RESOURCE/img/tiles-hintergrund.png);
						border-bottom: 1px solid var(--color-primary);
					}

					>.body.flex {
						background-image: url(RESOURCE/img/tiles-hintergrund.png);

						.conact-btn {
							@media(max-width:992px) {
								margin-top: 20px;
							}
						}
					}
				}
			}
		}

		/*@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}*/
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

}