.agb-v1 {
	margin-top: var(--page-margin-top);

	h2 {
		padding-bottom: 26px;
	}

	ol {
		margin-bottom: 0;
		font-weight: 300;

		li {
			&:last-child {
				p:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.inner-banner {
		border-bottom: 2px solid var(--color-primary);
		position: relative;

		&:after {
			content: "";
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
			background-image: url(RESOURCE/img/tiles-hintergrund.png);
			left: 0;
		}

		&.agb {
			background-image: url(RESOURCE/img/agb_neu.jpg);
		}
	}
}