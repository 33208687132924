.blog-page-layout-v1 {
	padding-top: var(--page-margin-top);

	@media (max-width:767px) {
		margin: 0;

	}

	.heading1 {
		margin-bottom: 40px;
	}
}