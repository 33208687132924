.unit-calendar {
	.widget-block {
		@media (max-width:767px) {
			padding-bottom: 0;
		}
	}

	.asd__wrapper {
		border: 1px solid var(--color-primary) !important;
		box-shadow: 0 0 8px rgb(0 0 0 / 15%);
	}

	.clear-button {
		margin-top: 20px;
	}

	.datepicker-trigger {

		display: inline-block;

		.asd__change-month-button {
			button {
				border: 1px solid var(--color-primary) !important;
				box-shadow: 0 0 8px rgb(0 0 0 / 15%);
			}
		}

		.asd__day--disabled,
		.asd__day--empty {
			opacity: 1.0 !important;
			cursor: not-allowed;
		}

		.asd__day-button {
			pointer-events: auto;
		}

		.asd__day-button:disabled,
		.asd__day-button[disabled] {
			pointer-events: none;
		}

		.asd__day--scope {
			background: var(--unit-click-not-possible-bg) !important;
			color: var(--unit-click-not-possible-color) !important;
		}

		.asd__day--ar-pos:not(.asd__day--disabled, .asd__selected-date-one, .asd__selected-date-two, .asd__day--in-range, .asd__day--departure) {
			background: var(--unit-click-possible-bg) !important;
			color: var(--unit-click-possible-color) !important;
		}

		.asd__day--arrival {
			background: linear-gradient(135deg, var(--unit-click-not-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important
		}

		.asd__day--arrival:not(.asd__day--disabled) {
			background: linear-gradient(135deg, var(--unit-click-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important
		}


		.asd__day--departure {
			background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-not-possible-bg) 50%) !important;
		}

		.asd__day--departure:not(.asd__day--disabled, .asd__selected-date-one) {
			background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-possible-bg) 50%) !important;
		}

		.asd__day--not-available {
			background: var(--unit-cal-not-available-bg) !important;
			color: var(--unit-cal-not-available-color) !important;
			opacity: 1 !important;
		}

		.asd__day--disabled.asd--day--in-range {
			background: var(--unit-cal-selection-range) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
		}

		.asd__day--enabled.asd__day--in-range {
			background: var(--unit-cal-selection-range) !important;
			color: var(--unit-cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;
		}

		.asd__day--enabled:not(.asd__day--disabled, .asd__day--arrival, .asd__day--departure, .asd__day--in-range, .asd__selected-date-one, .asd__selected-date-two) {
			background: var(--unit-click-possible-bg) !important;
			color: var(--unit-click-possible-color) !important;

			&:hover {
				background: var(--unit-cal-selected) !important;
				color: var(--unit-cal-text-color-hover) !important;
			}
		}

		.asd__day--enabled:not(.asd__day--disabled) {
			&:hover {
				background: var(--unit-cal-selected) !important;
				color: var(--unit-cal-text-color-hover) !important;
			}
		}

		.asd__day--disabled.asd__day--hovered {
			background: inherit;
			opacity: 1 !important;
			border: inherit;
			color: inherit;
		}

		.asd__day--enabled.asd__day--hovered {
			background: inherit;
			opacity: 1 !important;
			border: inherit;
			color: inherit;
		}


		.asd__day--selected {
			background: var(--unit-cal-selected) !important;
			color: var(--unit-cal-text-color-selected) !important;
			opacity: 1 !important;
			border: 1px double var(--unit-cal-selected-border) !important;

			/*Arrow display css start*/
			&.asd__selected-date-one {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-left: 12px solid transparent;
					border-top: 19px solid var(--unit-cal-selection-range);
					border-bottom: 20px solid var(--unit-cal-selection-range);
					right: 0px;
				}
			}

			&.asd__selected-date-two {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-right: 12px solid transparent;
					border-top: 19px solid var(--unit-cal-selection-range);
					border-bottom: 20px solid var(--unit-cal-selection-range);
					left: 0px;
				}
			}
		}

		&.searchbox {
			width: 100%;
		}

	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 15px 0;
		color: var(--color-black);
		font-size: var(--font-size-md);
		display: flex;
		justify-content: center;

		@media (max-width:992px) {
			flex-direction: column;
		}

		@media (max-width:767px) {
			margin: 15px 0 0;
		}

		.legend-lable {
			margin-top: 7px;

			@media (max-width:992px) {
				margin-top: 0;
			}
		}

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: start;
			width: 70%;

			@media (max-width:992px) {
				width: 100%;
				justify-content: center;
			}

			@media (max-width:767px) {
				justify-content: center;
				margin: 0;
			}
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			@media (max-width:767px) {
				justify-content: center;
			}

			/*&:first-child {
				@media (max-width:992px) {
					width: 100%;
				}
			}*/

			.day {
				width: 24px;
				height: 24px;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				@media (max-width:992px) {
					width: 15px;
					height: 15px;
					margin: 0 6px 0 0;
				}

				&.day-free {
					background: var(--unit-click-possible-bg);
				}

				&.day-full {
					background: var(--unit-cal-not-available-bg);
				}

				&.day-disabled {
					background: var(--unit-click-not-possible-bg);
				}

				&.day-selected-range {
					background: var(--unit-cal-selection-range);
				}

				&.day-end {
					background: linear-gradient(135deg, var(--unit-click-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important;
				}

				&.day-start {
					background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-possible-bg) 50%) !important
				}
			}
		}
	}
}

.tooltip.datepicker.show {
	opacity: 1 !important;
}

.datepicker {

	.tooltip-inner {
		background-color: white;
		border: 1px solid #ccc;
		font-size: 12px;
		color: #999;
		padding: 0.25rem 0.25rem;
	}

	.arrow {
		position: absolute;
		display: block;
		width: 0.8rem;
		height: 0.4rem;

		&:before {
			top: 0;
			border-width: 0.4rem 0.4rem 0;
			border-top-color: #fff;
		}
	}
}