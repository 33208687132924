.impressum-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		border-bottom: 2px solid var(--color-primary);
		position: relative;

		&:after {
			content: "";
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
			background-image: url(RESOURCE/img/tiles-hintergrund.png);
			left: 0;
		}

		&.impressum {
			background-image: url(RESOURCE/img/impressum_neu.jpg);
		}
	}
}