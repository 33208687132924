.footer-v1 {
	box-shadow: 0 0 8px rgb(0 0 0/15%);
	/*margin-bottom: 8px;*/
	font-family: var(--font-degular-variable);
	font-size: 18px;

	.logo-cont-bl {
		display: flex;

		@media (max-width:992px) {
			flex-direction: column;
		}

		@media (max-width:767px) {
			margin-top: 30px !important;
		}
	}

	.col-bl {
		padding: 0 15px;

		@media (max-width:992px) {
			-webkit-box-flex: 0;
			flex: 0 0 100%;
			max-width: 100%;
		}

		/*&:last-child {
			align-self: stretch;
		}*/
	}

	.footer-row {
		justify-content: space-between;
		align-items: flex-end;
		/*align-items: start;*/

		@media (min-width:993px) {
			flex-wrap: inherit;
		}
	}

	.main-footer {
		font-size: var(--font-size-md);
		background-color: var(--footer-bg-color);
		color: var(--color-primary);
		border-top: 1px solid var(--footer-border-top-color);
		box-shadow: 0 0 8px rgb(0 0 0 / 15%);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}


		position: relative;

		li {
			a {
				color: var(--color-primary);
			}
		}

		.col5 {
			display: flex;
			justify-content: end;
			padding: 0 15px;
			flex-direction: column;
			padding: 0 15px;

			@media(min-width:993px) {
				-webkit-box-flex: 0;
				flex: 0 0 20%;
				max-width: 20%;
			}
		}

		.headline {
			font-size: 30px;
			color: var(--color-primary);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 120px;
			height: auto;
			margin-right: 35px;
			box-shadow: 0 0 8px rgb(0 0 0 / 6%);
			border-radius: 50%;

			@media (max-width:1100px) {
				width: 100px;
				margin-right: 20px;
			}

			@media (max-width:992px) {
				margin-right: 0;
				padding-right: 0;
				margin-bottom: 15px;
			}
		}

		.v-office-logo {

			@media(min-width: 993px) {
				text-align: right;
			}

			@media(max-width: 993px) {
				text-align: left;
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

}