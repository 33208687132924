.unit-image-gallery-v1 {
	margin-right: -5px;
	margin-left: -5px;
	margin-top: -5px;

	.visible-print {
		display: none !important;
	}


	.unitGallery {
		overflow: hidden;
		position: relative;

		.image-placeholder {
			height: 680px;

			@media (max-width:767px) {
				height: 350px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.fav-element {
			cursor: pointer;

			.fa {
				border: 1px solid var(--color-primary);
				padding: 1px 9px;
				border-radius: 5px;
				font-size: 14px;
				font-weight: bold;
				height: 25px;
				align-items: center;
				display: inline-flex;
				font-family: var(--font-family-main);
				position: absolute;
				bottom: 24px;
				right: 24px;
				z-index: 5;
				background-color: var(--color-grey-light);

				&.fa-heart {
					&:before {
						content: "GERMERKT";
					}
				}

				&.fa-heart-o {
					&:before {
						content: "MERKEN";
					}
				}

			}
		}

		.owl-item {
			aspect-ratio: 4 / 3;

			img,
			iframe {
				width: 100%;
				height: 100%;
				object-fit: cover;
				aspect-ratio: 4 / 3;
			}

			.galleru-btn {
				position: absolute;
				bottom: 24px;
				left: 24px;
				z-index: 5;
				border: 1px solid var(--color-primary);
				padding: 1px 9px;
				border-radius: 5px;
				font-size: 14px;
				font-weight: bold;
				width: auto;
				height: 25px;
				align-items: center;
				display: inline-flex;
				font-family: var(--font-family-main);
				background-color: var(--color-grey-light);

				&:hover {
					text-decoration: none;
				}
			}
		}

		.owl-carousel {
			overflow: hidden;

			.owl-nav {
				button {
					width: 40px;
					height: 40px;
					top: 50%;
					border-radius: 50%;
					transform: translate(0, -50%);
					position: absolute;
					font-size: 0;
					cursor: pointer;
					transition: all 0.25s ease 0s;
					color: var(--color-primary);
					background-color: var(--color-white);
					font-size: 36px;
					line-height: 8px;
					text-align: center;
					font-weight: normal;

					@media(max-width:992px) {
						width: 30px;
						height: 30px;
					}

					span {
						position: relative;
						top: -4px;
					}
				}


				.owl-prev {
					left: 5px;
				}

				.owl-next {
					right: 5px;
				}
			}

			.owl-dots {
				position: absolute;
				width: 70%;
				bottom: 10px;
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: center;
				line-height: 1;
				left: 50%;
				transform: translateX(-50%);

				.owl-dot {
					background-color: #fff;
					width: 6px !important;
					height: 6px !important;
					opacity: .25;
					margin: 0 1px !important;
					border-radius: 50%;

					&.active {
						opacity: 1;
					}
				}
			}
		}
	}


	.unitGallery {
		a {
			display: none;
			padding: 5px;
			height: 150px;

			@media (max-width: 767px) {
				padding: 5px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:nth-child(1) {
				width: 100%;
				float: right;
				display: block;
				height: 540px;
				overflow: hidden;

				@media (max-width: 767px) {
					width: 100%;
					height: 400px;
				}
			}

			&:nth-child(2) {
				clear: both;

				@media (max-width: 767px) {
					clear: none;
					height: 140px;
				}
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				display: block;
				width: 20%;
				float: left;

				@media (max-width: 767px) {
					width: 50%;
					height: 140px;
				}
			}

			&:nth-child(6) {
				position: relative;

				&:before {
					content: "+ mehr";
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					bottom: 0;
					text-align: center;
					width: 100%;
					color: #fff;
					font-weight: 600;
					font-size: var(--font-size-md);
					margin-top: -10px;
					z-index: 1;
					padding: 0 10px;
				}

				&:after {
					content: "";
					position: absolute;
					left: 5px;
					right: 5px;
					top: 5px;
					bottom: 5px;
					background: rgba(0, 0, 0, .5);
					z-index: 0;

					@media (max-width: 767px) {
						top: 5px;
						bottom: 5px;
					}
				}

			}


		}
	}

}