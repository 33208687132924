.searchpanel-v1 {

	.sidebar-search {

		@media (max-width:992px) {
			background-image: url(https://r.v-office.com/preview/v1723/1707723783000/img/tiles-hintergrund-hell.png);
			border: 1px solid var(--color-primary);
			padding: 30px;
			box-shadow: 0 0 8px rgb(0 0 0/15%);
		}

		@supports (-webkit-touch-callout: none) {
			@media (max-width: 768px) {
				padding-bottom: 70px;
			}
		}

		.btn-aushwahl {
			text-align: center;

			a {
				border-color: var(--color-primary);
				border-radius: 0;
				width: 230px;
			}
		}

		.btn-filter-deletes {
			text-align: center;

			a {
				border-color: var(--color-primary);
				border-radius: 0;
				width: 230px;
				padding: .375rem 10px;
			}
		}

		.btn-karte {
			text-align: center;

			a {
				border-color: var(--color-primary);
				border-radius: 0;
				width: 230px;
			}
		}
	}

	.search-bar {
		padding: 10px 15px;
		background-color: var(--widget-header-bg-color);

		.title {
			font-size: var(--h4-font-size);
			font-weight: 500;
		}

	}

	.label-li {
		li {
			margin: 3px 0;
			padding: 0;
			font-size: var(--font-size-md);

			.checkbox {
				input {
					position: absolute;
				}
			}

			label {
				display: block;
				padding: 0;
				margin-bottom: 18px;
				font-size: 16px;
			}

			.checkbox label:before {
				width: 22px;
				height: 22px;
				border-radius: 0;
				border: 1px solid var(--color-primary);
				left: inherit !important;
				right: 0;
				background-color: var(--footer-bg-color);
				box-shadow: 0 0 8px rgb(0 0 0 / 15%);
			}

			.checkbox label:after {
				left: inherit;
				right: 5px;
				top: 2px;
				font-size: 14px;
			}
		}
	}

	.search-bar-content {
		padding: 0 15px 15px 15px;

		.label-li {
			li {
				.checkbox {
					padding-left: 0;
				}
			}
		}

		.filter-icon {
			.form-group {
				position: relative;
				align-items: center;
				margin-bottom: 30px;

				span {
					font-size: 16px;
				}

				.form-control {
					font-size: 14px;
					padding-right: 25px;
					font-weight: bold;
					height: auto;
					color: var(--color-primary);
				}
			}


			select {
				-webkit-appearance: none;
			}

			.fa {
				position: absolute;
				top: 7px;
				right: 24px;
				font-size: 1rem;
				opacity: 1;
				pointer-events: none;
			}
		}

	}

	.sidebar-search {
		@media (min-width:993px) {
			display: block !important;
		}

		.search-m-form {
			@media (min-width:993px) {
				display: none;
			}



			@media (max-width:992px) {
				background-color: var(--darkblue);
				padding: 15px 0;
				position: relative;
				z-index: 3000;
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}

		}
	}

	#searchpanel {
		.container {
			width: auto;
		}

		@media (min-width:992px) {
			position: relative;
			display: block !important;
			overflow: visible;

			.close {
				display: none;
			}

			&.modal {
				z-index: 3 !important;
			}

			&.fade {
				opacity: 1;
			}

			.modal-dialog {
				width: auto;
				-webkit-transform: translateY(0%);
				transform: translateY(0%);
				margin: 0;
				pointer-events: inherit;
			}
		}


		@media (max-width:992px) {
			.search-bar {
				border: 1px solid #eee;
				border-top: none;
			}

			&.modal.fade {
				.modal-dialog {
					-webkit-transform: translateX(-100%);
					transform: translateX(-100%);
					position: fixed !important;
				}

				&.show {
					.modal-dialog {
						-webkit-transform: translateX(0);
						transform: translateX(0);
						pointer-events: inherit;
					}
				}
			}
		}

		.modal-dialog {
			@media (max-width:992px) {
				margin: 0;
				padding: 50px 15px 15px;
				z-index: 20001;
				max-width: 650px;
				width: 100%;
				background-color: var(--color-grey-light);
				overflow-y: scroll;
				height: 100vh;

				>.close {
					padding: 0 8px 1px;
					position: absolute;
					top: 6px;
					right: 16px;
					z-index: 50;
					opacity: 1;
					font-size: 36px;
				}
			}
		}
	}

}