.search-unit-v1 {
	margin: 40px 0 66px;

	@media (min-width:1201px) {
		margin-left: 15px;
	}

	@media (max-width:1200px) {
		margin: 40px 0;
	}

	.unit-row {
		position: relative;

		@media(min-width:1201px) {
			&:after {
				content: "";
				width: 1px;
				height: calc(100% - -1px);
				position: absolute;
				background-color: #eee;
				left: -1px;
				bottom: -16px;

			}
		}
	}

	.details-bl {
		/*@media(max-width:1200px) {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-left: 15px;
		}*/

		.btn-primary {
			font-size: 13px;
			padding: 4px 12px !important;
			border-radius: 8px !important;
			margin-bottom: 23px;

			@media(max-width:1200px) {
				margin-bottom: 0;
			}
		}
	}

	.img-col {
		position: relative;

		@media (max-width: 1200px) {
			margin: 0 15px 0;
			box-shadow: 0 0 8px rgb(0 0 0 / 15%);
		}

		@media(min-width:1201px) {
			&:before {
				content: "";
				width: 20px;
				height: 1px;
				position: absolute;
				background-color: #eee;
				top: -1px;
				right: -15px;
			}
		}


		.img-content {
			/*border: 0;
			border-right: 1px solid #000;*/
			overflow: hidden;
			position: relative;
			aspect-ratio: 4 / 3;
			top: -25px;
			z-index: 1;

			@media (max-width: 767px) {
				overflow: visible;
			}

			@media(max-width:1200px) {
				top: 0;
				border: none;
				border-bottom: 1px solid var(--color-primary);
			}
		}





		.owl-carousel {
			overflow: hidden;

			@media (max-width: 767px) {
				overflow: visible;
			}

			.item {
				@media(max-width:1200px) {
					border-bottom: 1px solid var(--color-primary);
				}
			}

			.owl-nav {
				button {
					width: 25px;
					height: 25px;
					top: 50%;

					border-radius: 50%;
					transform: translate(0, -50%);
					position: absolute;
					font-size: 0;
					cursor: pointer;
					transition: all 0.25s ease 0s;
					color: var(--color-primary);
					background-color: var(--color-white);
					font-size: 36px;
					line-height: 8px;
					text-align: center;
					font-weight: normal;

					span {
						position: relative;
						top: -2px;
						height: 12px;
						display: inline-block;
						line-height: 0;
					}
				}


				.owl-prev {
					left: 5px;
				}

				.owl-next {
					right: 5px;
				}
			}

			.owl-dots {
				position: absolute;
				width: 70%;
				bottom: 10px;
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: center;
				line-height: 1;
				left: 50%;
				transform: translateX(-50%);

				.owl-dot {
					background-color: #fff;
					width: 6px !important;
					height: 6px !important;
					opacity: .25;
					margin: 0 1px !important;
					border-radius: 50%;

					&.active {
						opacity: 1;
					}
				}
			}

			/*	.owl-stage-outer,
			.owl-stage,
			.owl-item {
				height: 100%;
			}*/
		}
	}

	.full-img {
		width: 100%;
		height: 100%;
		object-fit: cover;

	}

	.col-xs-4 {
		position: relative;
		left: -15px;
		top: -15px;
	}

	.img-col {

		.ratings {
			position: absolute;
			right: 5px;
			bottom: 20px;
			background-color: var(--color-white);
			padding: 3px 6px;
			z-index: 1;
		}



		.item {
			aspect-ratio: 4 / 3;
		}

		@media (max-width: 1200px) {
			margin-right: 15px;
		}

		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
		}
	}

	.info-col {
		padding: 15px;
		font-size: 16px;
		padding-left: 30px;

		@media (max-width: 1200px) {
			padding: 10px 30px 30px;
		}

		@media (max-width: 1024px) {
			padding: 10px 29px 10px;
		}

		@media (min-width: 1201px) {
			-webkit-box-flex: 0;
			flex: 0 0 50%;
			max-width: 50%;
			justify-content: space-between;
			display: flex;
			flex-direction: column;
		}

		.unit-title {
			@media (max-width: 1024px) {
				margin-bottom: 15px !important;
			}
		}


		.unit-act-row {
			@media (max-width: 767px) {
				margin-top: 10px;
			}

			/*padding-top: 15px;*/
		}

		.unit-title {
			font-size: 28px;
			margin-bottom: 0;
			display: inline-block;
			display: flex;
			flex-wrap: wrap;
			text-transform: uppercase;

			span {
				margin-right: 8px;
			}

			&:hover {
				text-decoration: none;
			}

			.ratings {
				font-size: 16px;
				display: flex;
				justify-content: center;
				align-items: center;

			}
		}

		.seasonprices {
			font-size: 1.4rem;
			margin-top: 10px;
			display: block;
		}


	}

	.unit-price-col {
		padding-top: 18px;
		display: flex;

		justify-content: space-between;

		.details-bl {

			@media (max-width: 1200px) {
				align-items: flex-end;
			}

			@media (max-width: 767px) {
				display: flex;
				align-items: center;
			}
		}


		@media (max-width: 1200px) {
			padding: 15px 30px 15px 30px !important;
			align-items: center;
		}

		@media (min-width: 1200px) {
			-webkit-box-flex: 0;
			flex: 0 0 15%;
			max-width: 15%;
			flex-direction: column;
			text-align: right;
		}

		.fav-element {
			.fa {
				padding: 0px 9px;
				padding-bottom: 2px;
				line-height: 25px;
			}
		}

	}


	.tick-li {

		li {
			font-size: var(--font-size-md);
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			display: inline-block;

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-primary);
			}
		}

	}


	.properties-row {}

	.unit-properties {

		li {
			padding-right: 20px;
			display: inline-block;

			.fa {
				width: 30px;

				.fa-stack {
					margin-left: -10px;
					line-height: 1.4em;
				}
			}
		}
	}



	.rent {
		text-align: center;
	}

	.unit-price-zeit {
		/*justify-content: space-between;*/
		display: flex;
		padding-bottom: 2px;
		font-weight: normal;
		font-size: 14px;

		@media (min-width: 768px) {
			gap: 5px;
		}

		.date-bl {
			@media (min-width: 1201px) {
				margin-right: 20%;
			}
		}

		@media (max-width: 767px) {
			flex-direction: column;
		}
	}

	.small {
		font-size: var(--font-size-md);

		@media (min-width: 1201px) {
			&:after {
				content: "\A";
			}
		}
	}

	.main-price {
		font-weight: 600;
		text-decoration: line-through;
	}

	.discount-price {
		font-weight: 600;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.currency-bl {
			@media (min-width: 1201px) {
				margin-right: 22%;
			}
		}

		.reducedprice {
			color: var(--color-red);
		}
	}

	.btn-detail {
		border: 1px solid var(--color-primary);
		padding: 1px 9px;
		border-radius: 5px;
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 20px;
		text-decoration: none;
		padding: 0px 9px;
		padding-bottom: 2px;
		height: 25px;
		display: inline-flex;
		align-items: center;



		@media(max-width:1200px) {
			margin-bottom: 0;
		}

		@media(max-width:767px) {
			margin-right: 0;
			margin-bottom: 0;
		}

		&.left-aligned {
			margin-bottom: 15px;

			@media(max-width:767px) {
				margin-bottom: 0;
				position: absolute;
				left: 118px;
			}
		}
	}

	.prices {
		.rentRate {
			.h3 {
				font-size: 1.5rem;
				font-weight: bold;
				margin-top: 15px;
				font-family: var(--font-family-main);
			}
		}

		@media (max-width: 1200px) {
			text-align: left;
		}

	}

	.offer-box {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		display: flex;
		margin: 0;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		color: var(--color-black);
		border-top: 1px dashed var(--color-grey-normal);
		border-bottom: 1px dashed var(--color-grey-normal);
		margin-top: 15px;

		@media (max-width:1200px) {
			display: block
		}

		.offer-left {
			background: var(--color-secondary-light);
			color: var(--color-black);
			padding: 10px 15px;
			border: 1px dashed var(--color-primary);
			font-size: 16px;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-right: 15px;

			@media (max-width:1200px) {
				display: block;
				text-align: center;
				margin-right: 0;
				margin-bottom: 15px
			}
		}

		ul {
			padding-top: 8px;
			list-style: none;
			margin: 0;

			li {
				margin: 0 0 5px;
				line-height: 14px;
				font-size: 14px
			}
		}
	}

	.alternatives {
		margin-top: 0;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 1px;
		font-size: 14px;

		@media (max-width:1200px) {
			margin-left: 22px;
		}

		.altheading {
			font-weight: 600;
			color: var(--color-red);

		}



		a {
			color: var(--font-color-main);
			text-decoration: underline !important;

			&:hover {
				text-decoration: none !important;
				color: var(--color-red);
			}


		}

		.fa {
			color: var(--color-red);
			font-size: 12px;
			line-height: 16px;
		}

		.invalid {
			text-decoration: line-through;
		}

		.special {
			color: var(--color-red);
			font-weight: 600;
		}

	}



}