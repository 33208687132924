.search-bar-container {
	position: absolute;
	bottom: 121px;
	left: 50%;
	transform: translate(-50%, 0);
	width: 100%;
	padding: 0 15px;
	z-index: 5;

	@media (max-width: 992px) {
		position: relative;
		bottom: auto;
		margin-top: -140px;
		left: inherit;
		transform: inherit;
	}

	@media (max-width: 767px) {
		margin-top: -140px;
		margin-bottom: 0;
	}
}

.searchbar-v1 {
	width: 100%;
	display: flex;
	left: 0;
	right: 0;
	border: 1px solid var(--color-primary);
	box-shadow: 0 0 8px rgb(0 0 0 / 15%);
	background-image: url(RESOURCE/img/tiles-hintergrund-hell.png);

	@media (min-width:993px) {
		max-width: 800px;
	}

	>.container {
		padding: 0;

	}




	.title {
		color: var(--color-primary);
		background-color: var(--color-secondary-light);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 6px 20px;
		opacity: 0.8;

		@media (min-width:993px) {
			display: none;
		}
	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		/*background-color: #fff;
		background-color: var(--white);*/
		border-bottom: 1px solid #eaeaea;
		flex-flow: wrap;
		/*box-shadow: 0 2px 12px rgb(254 154 0 / 25%);*/

		.form-flex {
			width: 30%;
			padding: 6px;
			border-right: 1px solid #e9e3e3;

			&.v2 {
				width: 45%;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #e9e3e3;
				border-right: none;
				padding: 6px 12px;

				&.v2 {
					width: 100%;
				}
			}

			.fa {
				position: absolute;
				right: 10px;
				top: 10px;
			}

			.form-control {
				padding: 5px 15px;
				background-color: var(--color-grey-light);
				border: none;
				text-align: left;

			}

			>label {

				color: var(--black);
				font-size: 14px;
				display: block;
				font-weight: bold;
				letter-spacing: 0;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding: 0 6px;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
			}

			.btn {
				width: 101%;
				height: 65px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}