.unit-title-v1 {
	h1 {
		text-transform: uppercase;
		font-size: 3rem;
		margin: 0;
		font-family: var(--font-dengular-display);

		color: var(--color-primary);

		@media (max-width:992px) {
			font-size: 2rem;
		}
	}

	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}
}