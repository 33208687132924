.unit-booking-v1 {

	.search-bl {
		position: relative;

		.fa {
			position: absolute;
			right: 12px;
			top: 12px;
			color: var(--color-primary);
			pointer-events: none;
		}
	}

	.form-control {
		padding: 5px 15px;
		background-color: #f2f2f2;
		background-color: var(--color-grey-light);
		border: none;
		text-align: left;
		cursor: pointer;
		/*border: none;
		border-bottom: 1px solid #e6e6e6;
		text-align: left;
		margin-bottom: 15px;
		padding: 10px 0;
		background-color: var(--color-white);
		font-size: var(--font-size-md);
		*/
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

		.btn {
			@media (max-width:767px) {
				margin-bottom: 15px;
			}
		}
	}

	.subrow {
		margin-top: 20px;
	}

	.btn-option {
		margin-top: 10px;
	}
}