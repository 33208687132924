.welcome-text {
	padding-top: 50px;

	p {
		font-size: 1.125rem;
	}
}

.index-banner {
	position: relative;
}

.aktuelles-bl {
	margin-bottom: 30px;

	.blogcontent {
		max-width: 390px;
		margin: 0 auto;
		text-align: left;

		.img-thumbnail {
			margin-bottom: 30px !important;

			border: 1px solid var(--color-primary);
			box-shadow: 0 0 8px rgb(0 0 0 / 15%);
		}

	}



	.heading1 {
		font-size: 44px;
		margin-bottom: 30px;

		@media(max-width:993px) {
			font-size: 36px;
		}
	}

	p {
		font-size: 1.125rem;
	}
}

.contact-phone-bl {
	position: relative;
	margin-bottom: 60px;

	@media (min-width:993px) {
		padding-left: 20%;
		margin-top: 100px;

	}


	.checkbox label:before {
		width: 20px;
		height: 18px;
		background-color: var(--nav-bg-color);
		top: 3px;
		border-radius: 0;
	}

	.checkbox label:after {
		left: -1px !important;
		top: 3px !important;
	}

	.frame-bl {
		&.phone-bl {
			text-align: center;
			margin-bottom: 30px;
			width: 100%;

			@media (min-width:993px) {
				max-width: 340px;
				text-align: center;
				position: absolute;
				left: 0;
				z-index: 1;
				padding: 75px 30px;
				top: -8%;
				margin-bottom: 0;
			}

			h3 {
				font-size: 20px;
				margin-bottom: 50px;
			}

			a {
				span {
					font-weight: bold;
					font-size: 24px;
				}
			}
		}

		&.contact-bl {
			@media (min-width:993px) {
				max-width: 750px;
				position: relative;
				padding-left: 180px;
			}



			.form-group {
				margin-bottom: 3px;

				.control-label {
					margin-bottom: 3px;
				}
			}


			.icon-letter {
				width: 90px;

				@media (min-width:993px) {
					position: absolute;
					left: 52px;
					bottom: 124px;

				}
			}

			.contact-form-v1 {
				padding-bottom: 0 !important;

				.col-sm-12.text-md-right {
					margin-top: 15px;
					padding-right: 35px;
					text-align: left !important;

					/*@media (max-width:992px) {
						padding: 0;
					}*/
				}

				.btn-go {
					position: absolute;
					right: 90px;
					bottom: -16px;
					border-radius: 8px;
					height: 32px;
					line-height: 1;

					@media (max-width:767px) {
						right: 45px;
						left: auto;
					}

					&:before {
						content: "";
						background: url(RESOURCE/img/placehoder_img2.jpg) no-repeat 0 0;
						position: absolute;
						left: -9px;
						height: 20px;
						width: 8px;
						top: 5px;
						z-index: 0;
					}

					&:after {
						content: "";
						background: url(RESOURCE/img/placehoder_img2.jpg) no-repeat 0 0;
						position: absolute;
						right: -9px;
						height: 20px;
						width: 8px;
						top: 5px;
						z-index: 0;
					}
				}


			}
		}

	}
}

.section-bl {
	.page-title {
		top: 10px;
		bottom: inherit;

		@media (max-width: 992px) {
			position: relative;
			inset: 0;
		}

		@media (max-width:767px) {
			margin: 0 0 25px;
		}

		.pag-title {
			text-align: center;
			box-shadow: 0 0 8px rgb(0 0 0/15%);
			padding: 22px 25px 23px;
			font-family: var(--font-dengular-display);
			font-weight: 400;

			@media (min-width:993px) {
				position: relative !important;
				/*bottom: 55px !important;*/
				bottom: 52px !important;
			}

			@media (max-width:992px) {
				font-size: 23px;
				position: relative;
				bottom: inherit;
				display: block;
			}




		}
	}
}




.newsletter-box {
	/*margin-bottom: 60px;*/

	.icon-mail {
		@media (max-width:767px) {
			margin-bottom: 0;
			text-align: center;
		}

		img {
			margin-left: 25px;
			width: 60px;
			margin-top: 10px;


			@media (max-width:767px) {
				margin: 0;

			}
		}
	}

	.col-sm-12.text-md-right {
		margin-top: 15px;
		padding-right: 35px;
		text-align: left !important;

		@media (max-width:992px) {
			padding: 0;
		}
	}

	.widgt-grey-bl {
		margin-left: -20px;

		@media (max-width:767px) {
			margin-left: 0;
			padding: 0 15px;
		}
	}

	.widt-cont-bl {
		position: relative;
		display: flex;
		align-items: center;
		padding-right: 15px;
		flex-direction: column;
		align-items: start;

		@media (max-width:767px) {
			flex-direction: column;
			align-items: start;
			padding-right: 0;
		}

		label {
			margin: 0 20px 0 0;
		}
	}

	.checkbox {
		label {
			&:before {
				width: 20px;
				height: 18px;
				background-color: #f2f2f2;
				background-color: var(--nav-bg-color);
				top: 3px;
				border-radius: 0;
				margin-left: -22px;
			}

			&:after {
				top: 3px;
			}
		}
	}


	.btn-primary {
		position: absolute;
		right: 60px;
		bottom: -110px;
		border-radius: 8px;
		height: 32px;
		line-height: 1;

		@media (max-width:767px) {
			right: 0;

		}

		@media (max-width:402px) {
			bottom: -133px;
		}

		&:before {
			content: "";
			background: url(RESOURCE/img/placehoder_img2.jpg) no-repeat 0 0;
			position: absolute;
			left: -9px;
			height: 20px;
			width: 8px;
			top: 5px;
			z-index: 0;
		}

		&:after {
			content: "";
			background: url(RESOURCE/img/placehoder_img2.jpg) no-repeat 0 0;
			position: absolute;
			right: -9px;
			height: 20px;
			width: 8px;
			top: 5px;
			z-index: 0;
		}

	}
}

.doc-box {

	@media (max-width:767px) {
		margin-left: 22px;
	}
}

.contact-form {
	.checkbox {
		@media (max-width:767px) {
			padding-left: 5px !important;
		}
	}
}

.newsletter-box {
	.checkbox {
		margin-left: -35px;

		@media (max-width:992px) {
			margin-left: -20px;
		}

		@media (max-width:767px) {
			padding: 0 20px;
			margin-left: 0;
		}
	}
}

label {
	@media (max-width:767px) {
		line-height: 22px;
	}

}