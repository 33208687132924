.static-v1 {
	margin-top: var(--page-margin-top);

	&.saisonzeiten-v1 {
		table {
			thead:first-child {
				th {
					border: none;
					background-image: url(https://r.v-office.com/preview/v2126/1707723783000/img/tiles-hintergrund-hell.png);
					border: 1px solid var(--color-primary);
					box-shadow: 0 0 8px rgb(0 0 0 / 15%);
					border-color: var(--color-primary);
				}
			}
		}
	}

	&.uber-uns {
		h3 {
			strong {
				font-weight: var(--h4-font-weight);
				font-size: var(--h4-font-size);
				text-transform: uppercase;
			}

		}

		p:last-child {
			margin-bottom: 0;
		}
	}


	.inner-banner {
		border-bottom: 2px solid var(--color-primary);
		position: relative;

		&:after {
			content: "";
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
			background-image: url(RESOURCE/img/tiles-hintergrund.png);
			left: 0;
		}

		&.uber-uns {
			background-image: url(RESOURCE/img/ueberuns_neu.jpg);
		}

		&.saisonzeiten {
			background-image: url(RESOURCE/img/saisonzeiten_neu.jpg);
		}

		&.fragen {
			background-image: url(RESOURCE/img/fragen_neu.jpg);
		}

	}

	.list-no {
		list-style: auto;
		margin-left: 20px;

		li {
			margin-bottom: 20px;
		}
	}

	&.saisonzeiten-v1 {
		.table {
			margin: 0 auto;
		}
	}
}