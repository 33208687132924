.search-page-layout-v1 {

	margin-top: var(--page-margin-top);

	@media (max-width:992px) {
		margin-top: 90px
	}

	.search-tocken {
		.mysearch {
			.my-search-token {
				border: 1px solid var(--color-primary);
				box-shadow: 0 0 8px rgb(0 0 0 / 15%);
			}
		}
	}

	.col-xl-3 {
		@media (min-width: 1200px) {
			-webkit-box-flex: 0;
			flex: 0 0 30%;
			max-width: 30%;
		}
	}

	.legende-bl {
		display: flex;
		color: var(--color-primary);
		justify-content: center;
		margin-top: 40px;

		@media (max-width:1200px) {
			display: block;
			text-align: center;

			span {
				display: block;
			}
		}

		@media (max-width:767px) {
			margin-top: 25px;
		}

		p {
			margin: 0 15px;
		}

	}

	.search-tocken {
		.mysearch {
			.close {
				margin-left: 2px;
				padding-left: 4px;
				padding-bottom: 0px;
				font-size: 22px;
				font-weight: 400;
				position: relative;
				top: -2px;
			}
		}
	}

	.col-xl-9 {
		@media (min-width: 1200px) {
			-webkit-box-flex: 0;
			flex: 0 0 70%;
			max-width: 70%;
		}
	}

	.map-view {
		.search-map-pane-v1 {
			display: block;
			margin-bottom: 30px;
		}
	}

	.list-view {
		.search-map-pane-v1 {
			display: none;
		}
	}
}